
























import { Vue, Component } from 'vue-property-decorator'
import { getError } from '@/utils/helpers'
import AuthService from '@/services/AuthService'

@Component
export default class ForgotPassword extends Vue {
    email = '';
    error = '';
    message = '';

    forgotPassword () {
        this.error = ''
        this.message = ''
        const payload = {
            email: this.email
        }
        AuthService.forgotPassword(payload)
            .then((response) => {
                this.message = response.data.message
            })
            .catch((error) => (this.error = getError(error)))
    }
}
